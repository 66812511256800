// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"94d956eaa00995d6ca88bd2981f8d5f39e8de9be"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'live') {
  Sentry.init({
    dsn: 'https://9da0acf0ad726e07ff413cbe8d59292e@o4508091108622336.ingest.us.sentry.io/4508095805456384',

    // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,
  });
}
